import {
  Button,
  Input,
  InputGroup,
  InputLeftAddon,
  InputLeftElement,
  InputRightElement,
} from '@chakra-ui/react'
import Logo from '../../../assets/logo.svg'
import Image from 'next/image'
import { PhoneIcon } from '@chakra-ui/icons'
import Link from 'next/link'
import { FormEvent, FormEventHandler, useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import Toaster from '../../Toaster/Toaster'
import { useSession } from '../../../lib/hooks/auth'
import { useSignup } from '../../../lib/hooks/auth/useSignup'
import { VerificationProps } from './interface'
import OTPInput from '../../OtpInput'
import { useVerifyOTP } from '../../../lib/hooks/auth/useVerifyOTP'
import { resendOtp, verifyOtp } from '../../../utils/services'
import { IOtpData } from '../../../lib/schema/auth'

export const Verification = ({
  phone,
  responseError,
  onVerify = () => null,
  onBackToLogin = () => null,
}: VerificationProps) => {
  // const { mutate, isPending, isError, error } = useVerifyOTP()
  const [otpCode, setOtpCode] = useState('')
  const [timer, setTimer] = useState(0)
  const [otpData, setOtpData] = useState(null)

  const router = useRouter()

  useEffect(() => {
    const countdown = setInterval(() => {
      setTimer((prevTimer) => {
        if (prevTimer > 0) {
          return prevTimer - 1
        } else {
          clearInterval(countdown)
          return 0
        }
      })
    }, 1000)

    return () => clearInterval(countdown)
  }, [timer])
  useEffect(() => {
    handleResendOTP()
  }, [])

  const handleSubmit = async () => {
    let payload = {
      otp: otpCode,
      token: otpData?.verification_token,
    }
    try {
      let response = await verifyOtp(payload)
      if (response?.data?.status?.isSuccessful) {
        onVerify()
        Toaster.success('Your account has been verified.')
      }
    } catch (error) {}
  }
  const handleResendOTP = async () => {
    let payload = {
      phone: phone,
    }
    try {
      let response = await resendOtp(payload)
      if (response.data?.data) {
        setOtpData(response.data?.data)
        setTimer(120)
      }
    } catch (error) {
    }
  }

  const maskedPhoneNumber = (phone: string) => {
    return `03*****${phone.slice(-4)}`
  }

  return (
    <div className="p-8 flex flex-col justify-center items-center gap-4">
      <Image
        style={{
          cursor: 'pointer',
          marginBottom: '1rem',
        }}
        src={Logo}
        width={300}
        alt="logo"
      />
      <span className="text-primary-blue-600 font-semibold text-4xl">
        Verification
      </span>
      {responseError && (
        <span className="text-red-500 font-medium max-w-[22rem] text-center">
          {responseError}
        </span>
      )}
      <span className="text-neutral-gray-500 font-medium text-xl max-w-[22rem] text-center">
        Enter the 6 character verification code we sent to you at{' '}
        {phone && maskedPhoneNumber(phone)}
      </span>
      <div className="text-neutral-gray-500 font-medium max-w-[22rem] text-center border rounded-xl px-2">
        Dev OTP code: {otpData?.otp}
      </div>

      <div className="flex flex-col gap-4">
        <OTPInput
          length={6}
          onChange={(otp) => {
            setOtpCode(otp)
          }}
        />
        <Button
          variant="primary"
          size="lg"
          type="submit"
          onClick={handleSubmit}
          // mt="1rem"
        >
          Verify
        </Button>
      </div>
      {timer > 0 ? (
        <span className="text-neutral-gray-500 mt-[1rem]">
          Having trouble? Request a new OTP in{' '}
          <time style={{ color: '#001662' }}>{`${timer} seconds`}</time>
        </span>
      ) : (
        <button className="mt-[1rem]" onClick={() => handleResendOTP()}>
          Resend OTP
        </button>
      )}
      <span
        className="text-accent-orange-500 cursor-pointer"
        onClick={onBackToLogin} // Call the prop function to handle "Back to Login"
      >
        Back to Login
      </span>
    </div>
  )
}
