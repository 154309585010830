import React from 'react'
import Head from 'next/head';

interface Props {
  title: string
}
const Metas = (props: Props) => {
  return (
    <Head>
        <link rel="icon" href="./fav.svg" />
        <title>{props.title}</title>
    </Head>
  )
}

export default Metas