// pages/LoginPage.tsx
import React from 'react'
import { useState } from 'react'
import Image from 'next/image'
import axios from '../../utils/axios'
import styles from './login.module.scss'
import { useRouter } from 'next/router'
import Logo from '../..//assets/logo.svg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons'
import Toaster from '../../components/Toaster/Toaster'
import { loginApi } from '../../utils/services'
import { Login } from '../../components/Auth/Login/Login'
import { AuthPage } from '../../components/layout/AuthPage'
import Metas from '../../utils/Metas'

const login = () => {
  return (
    <>
      <Metas title='Login | Qadam.io' />
      <AuthPage>
        <Login />
      </AuthPage>
    </>
  )
}

export default login
