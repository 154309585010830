import React from 'react'
import bannerNew from '../../../assets/bannerNew.png'

export const AuthPage = ({ children }: { children: React.ReactNode }) => {
  return (
    <div className="overflow-hidden w-full h-full grid grid-cols-1 md:grid-cols-2">
      <div className="hidden md:block w-full h-full pb-10">
        <div className="mb-[4rem]">
          <img
            height={500}
            width={570}
            src={bannerNew.src}
            alt="Branding"
            className="max-w-full max-h-full object-contain"
          />
        </div>
      </div>
      <div className="flex items-center justify-center w-full h-[calc(100vh-5rem)]">
        {children}
      </div>
    </div>
  )
}
