import {
  Button,
  Input,
  InputGroup,
  InputLeftElement,
  InputLeftAddon,
  InputRightElement,
} from '@chakra-ui/react'
import Logo from '../../../assets/logo.svg'
import Image from 'next/image'
import { PhoneIcon } from '@chakra-ui/icons'
import {ViewIcon, ViewOffIcon } from '@chakra-ui/icons'
import Link from 'next/link'
import { FormEvent, FormEventHandler, useEffect, useState } from 'react'
import { useLogIn } from '../../../lib/hooks/auth/useLogIn'
import { useRouter } from 'next/router'
import Toaster from '../../Toaster/Toaster'
import { useSession } from '../../../lib/hooks/auth'
import OTPInput from '../../OtpInput'
import { useVerifyOTP } from '../../../lib/hooks/auth/useVerifyOTP'
import { requestOtp, resendOtp, resetPassword } from '../../../utils/services'
import { Constants } from '../../../utils/Constants'
import { Verification } from './Verification'
import { setCookie } from 'cookies-next'
import { ErrorMessage } from './interface'

export const Login = () => {
  const {
    mutate: loginMutate,
    isPending: isLoginPending,
    isError,
    error,
  } = useLogIn()
  const { mutate: verifyOtpMutate, isPending: isVerifyPending } = useVerifyOTP()
  const [showForgotPass, setShowForgotPass] = useState(false)
  const [showOtpScreen, setShowOtpScreen] = useState(false)
  const [timer, setTimer] = useState(120)
  const { logIn } = useSession()
  const router = useRouter()
  const [showVerification, setShowVerification] = useState(false)
  const [responseError, setResponseError] = useState('')
  const [loginPhone, setLoginPhone] = useState('')
  const [loginPassword, setLoginPassword] = useState('')
  const [phoneError, setPhoneError] = useState('')
  const [passwordError, setPasswordError] = useState('')
  const [showPassword, setShowPassword] = useState(false)


  const handleSubmit = () => {
    const phone = '0' + loginPhone
    const password = loginPassword

    if (!validatePhone(phone.slice(1))) {
      setPhoneError('Phone number must be exactly 10 digits')
      return
    } else {
      setPhoneError('')
    }

    if (!validatePassword(password)) {
      setPasswordError('Password must be at least 8 characters')
      return
    } else {
      setPasswordError('')
    }

    loginMutate(
      { phone, password },
      {
        onSuccess: (res) => {
          logIn({
            accessToken: res.data.data.access_token,
            refreshToken: res.data.data.refresh_token,
            accessTokenExpireTime: Date.now() + res.data.data.expires_in * 1000,
            refreshTokenExpireTime:
              Date.now() + res.data.data.refresh_expires_in * 1000,
            userData: res.data.data.user_data,
          })

          if (!res?.data?.data?.user_data?.terms_accepted) {
            router.push('/terms-and-conditions')
          } else {
            if (router?.query?.redirect) {
              router.push(router?.query?.redirect as string)
            }
            if (
              res.data.data.user_data?.profile_verification?.status ==
                'incomplete' ||
              res.data.data.user_data?.bank_details?.verification?.status ==
                'incomplete'
            ) {
              Toaster.error(
                res.data.data.user_data?.profile_verification?.status ==
                  'incomplete'
                  ? 'Your profile is incomplete. Please complete it first.'
                  : 'Your bank details are incomplete. Please complete it first.'
              )
              router.push('/profile')
              return
            }

            router.push('/home')
          }
        },
        onError: (e?: any) => {
          if (e.status.message == 'phone is not verified') {
            setResponseError('Your account is not verified.')
            setShowVerification(true)
            return
          }
          if (e.status.message == 'Invalid user credentials') {
            setResponseError(e.status?.message)
            return
          }
          setResponseError(e.status?.message)
        },
      }
    )
  }

  const validatePhone = (phone: string) => {
    const regex = /^\d{10}$/
    return regex.test(phone)
  }

  const validatePassword = (password: string) => {
    return password.length >= 1
  }

  return (
    <>
      {!showVerification ? (
        <div className="p-4 md:p-8 flex flex-col justify-center items-center gap-4 w-full">
          <span className="text-primary-blue-600 font-semibold text-2xl md:text-4xl">
            Welcome Back!
          </span>
          <span className="text-neutral-gray-500 font-semibold text-lg md:text-xl text-center">
            Please sign in to your account
          </span>
          <span className="text-red-500 font-medium max-w-full md:max-w-[22rem] text-center">
            {responseError}
          </span>
          <div className="flex flex-col gap-4 w-full md:w-auto px-4 md:px-0">
            <InputGroup className="w-full md:w-[22rem]">
              <InputLeftAddon>+92</InputLeftAddon>
              <InputRightElement>
                <PhoneIcon color="gray.300" />
              </InputRightElement>
              <Input
                onChange={(event) => setLoginPhone(event.target.value)}
                value={loginPhone}
                name="phone"
                type="tel"
                placeholder="Phone number"
                required
                className="w-full"
              />
            </InputGroup>
            {phoneError && (
              <span className="text-red-500 text-sm">{phoneError}</span>
            )}
           <InputGroup className="w-full md:w-[22rem]">
              <Input
                name="password"
                type={showPassword ? 'text' : 'password'}
                placeholder="Password"
                required
                onChange={(event) => setLoginPassword(event.target.value)}
                value={loginPassword}
                className="w-full"
              />
              <InputRightElement
              // width="3rem"
              // style={{ paddingRight: '10px' }} // Adjust padding to align with phone icon
              >
                <Button
                  h="1.5rem"
                  size="sm"
                  onClick={() => setShowPassword(!showPassword)} // Toggle state on button click
                  bg="transparent" // Remove background color
                  _hover={{ bg: 'transparent' }} // Ensure no background on hover
                  _active={{ bg: 'transparent' }} // Ensure no background on active state
                  _focus={{ boxShadow: 'none' }} // Remove focus outline
                >
                  {showPassword ? <ViewOffIcon /> : <ViewIcon />}
                </Button>
              </InputRightElement>
            </InputGroup>
            {passwordError && (
              <span className="text-red-500 text-sm">{passwordError}</span>
            )}
            <Button
              variant="primary"
              size="lg"
              type="submit"
              isLoading={isLoginPending}
              mt="1rem"
              onClick={handleSubmit}
              className="w-full md:w-auto"
            >
              Login
            </Button>
          </div>
          <span className="font-medium text-base md:text-lg mt-4">
            Don't have an account?{' '}
            <Link className="text-accent-orange-500" href="/signup">
              Sign Up
            </Link>
          </span>
          <span
            className="text-accent-orange-500 cursor-pointer mt-2 md:mt-4"
            onClick={() => router.push('forgot-password')}
          >
            Forgot Password?
          </span>
        </div>
      ) : (
        <>
          <Verification
            responseError={responseError}
            phone={'0' + loginPhone}
            onVerify={() => handleSubmit()}
            onBackToLogin={() => setShowVerification(false)}
          />
        </>
      )}
    </>
  )
}
